import { Pipe, PipeTransform } from '@angular/core';
import { CartItem } from '@lobos/common-v3';
import { SuedoSalesHistoryItem } from '../interfaces/suedo-sales-history-item.interface';
import { ArticleHelperService } from '../services/catalog/article-helper.service';

@Pipe({
  name: 'cartItemIsMaster',
  standalone: true,
})
export class CartItemIsMasterPipe implements PipeTransform {
  transform<T extends CartItem | SuedoSalesHistoryItem>(items: T[] | undefined): T[] {
    return items ? items.filter((item: T) => ArticleHelperService.itemIsMaster(item)) : [];
  }
}
