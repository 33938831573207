import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CartItem } from '@lobos/common-v3';
import { SuedoSalesHistoryItem } from '../../interfaces/suedo-sales-history-item.interface';

@Injectable({
  providedIn: 'root',
})
export class ArticleHelperService {
  constructor(@Inject(PLATFORM_ID) private _platformId: any) {}

  public static itemIsMaster(item: CartItem | SuedoSalesHistoryItem): boolean {
    if (item.shtFixedItemIDItemGroup) {
      return item.shtFixedItemIDItemGroup === 0 || item.shtFixedItemIDItemGroup === item.shtFixedItemID;
    } else {
      return true;
    }
  }

  public static itemIsChildOf(
    child: CartItem | SuedoSalesHistoryItem,
    master: CartItem | SuedoSalesHistoryItem,
  ): boolean {
    if (child.shtFixedItemIDItemGroup) {
      return (
        child.shtFixedItemIDItemGroup !== 0 &&
        child.shtFixedItemIDItemGroup === master.shtFixedItemIDItemGroup &&
        child.shtFixedItemIDItemGroup !== child.shtFixedItemID
      );
    } else {
      return false;
    }
  }
}
